<template>
   <header class="hero">
        <div class="hero-content">
            <h1>Welcome to the Tatabook</h1>
            <p>India ka no. 1 Plateform, Khelo original ke sath.</p>
            <a href="javascript:void(0)" @click="redirectToWhatsapp()" class="cta-button">Chat with us</a>
        </div>
    </header>
     
    <section id="explore" class="explore-section">
        <p>Disclaimer:- This Website is only for 18+ users. If you are from Telangana, Orissa, Assam, Sikkim, and Nagaland Please leave the website immediately. Be aware of fraudsters, we only deal via WhatsApp.
We Only Promote Fantasy Sports.</p>
        <h5>No Real Money Involvement</h5>
    </section>
    <footer class="footer">
        <p>&copy; 2024 Tatabook. All rights reserved.</p>
    </footer>
</template>

<script>
export default {
name:"tata",
mounted() { 
        document.body.addEventListener('click', this.redirectToWhatsapp);
    },
    methods: {
       
        redirectToWhatsapp() {
                window.location.replace("whatsapp://send?phone=916264223015&text=Hi%20Tatabook%2C%20I%20want%20new%20Id");
            
        }
    },
}
</script>

<style>
/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Body Styles */
body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    background: #f0f8ff; /* Light background for a fantasy feel */
}

/* Hero Section */
.hero {
    text-shadow: 0 0 5px #fff,0 0 10px #fff,0 0 15px #fff,0 0 20px #228dff,0 0 35px #228dff,0 0 40px #228dff;
    background-image: url('./assets/fantasy-background.png'); /* A beautiful fantasy-themed background image */
    background-size: cover;
    background-position: center;
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: white; */
    text-align: center;
    position: relative;
    overlay: rgba(0, 0, 0, 0.5); /* Dark overlay for better text visibility */
}

.hero-content {
    z-index: 2; /* Ensure the content is above the overlay */
}

.hero h1 {
    font-size: 3rem; /* Responsive font size */
    margin-bottom: 20px;
}

.hero p {
    font-size: 1.5rem; /* Responsive font size */
    margin-bottom: 30px;
}

/* Call to Action Button */
.cta-button {
    text-shadow: none;
    background-color: #49e63d; /* Tomato color for the button */
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;
    font-weight: bold;
}

.cta-button:hover {
    /* background-color: #ff4500; Darker shade on hover */
}

/* Explore Section */
.explore-section {
    padding: 50px 20px;
    text-align: center;
    background: #fff; /* White background for contrast */
}

.explore-section h2 {
    font-size: 2.5rem; /* Responsive font size */
    margin-bottom: 20px;
}

.explore-section p {
    font-size: 1.2rem; /* Responsive font size */
    /* max-width: 600px; */
    margin: 0 auto; /* Center the text */
}

/* Footer */
.footer {
    text-align: center;
    padding: 20px;
    background: #333; /* Dark background for footer */
    color: white;
}

/* Responsive Design */
@media (max-width: 768px) {
    .hero h1 {
        font-size: 2.5rem; /* Smaller font size on mobile */
    }

    .hero p {
        font-size: 1.2rem; /* Smaller font size on mobile */
    }

    .cta-button {
        font-size: 1rem; /* Smaller font size on mobile */
        padding: 10px 20px; /* Adjust padding on mobile */
    }

    .explore-section h2 {
        font-size: 2rem; /* Smaller font size on mobile */
    }

    .explore-section p {
        font-size: 1rem; /* Smaller font size on mobile */
    }
}

@media (max-width: 480px) {
    .hero h1 {
        font-size: 2rem; /* Even smaller for very small devices */
    }

    .hero p {
        font-size: 1rem; /* Even smaller for very small devices */
    }
}
</style>
