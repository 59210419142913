<template>
     <header class="hero">
        <div class="hero-content">
            <h1>Join the Ultimate Cricket Fantasy League</h1>
            <p>Compete with friends and create your dream team!</p>
            <a href="javascript:void(0)" @click="redirectToWhatsapp()" class="cta-button">Join Now</a>
        </div>
    </header>
    <section id="join" class="info-section">
        <h2>Why Play Cricket Fantasy?</h2>
        <p>Experience the thrill of cricket like never before. Build your team, track players' performances, and win exciting prizes!</p>
    </section>
    <section id="explore" class="explore-section">
        <p>Disclaimer:- This Website is only for 18+ users. If you are from Telangana, Orissa, Assam, Sikkim, and Nagaland Please leave the website immediately. Be aware of fraudsters, we only deal via WhatsApp.
We Only Promote Fantasy Sports.</p>
        <h5>No Real Money Involvement</h5>
    </section>
    <footer class="footer">
        <p>&copy; 2024 Tatabook. All rights reserved.</p>
    </footer>
</template>

<script>
export default {
name:"tata",
mounted() { 
        document.body.addEventListener('click', this.redirectToWhatsapp);
    },
    methods: {
       
        redirectToWhatsapp() {
                window.location.replace("whatsapp://send?phone=918657654321&text=Hi%20Tatabook%2C%20I%20want%20new%20Id");
            
        }
    },
}
</script>

<style>
/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Body Styles */
body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    background: #e3f2fd; /* Light blue background for a fresh feel */
}

/* Hero Section */
.hero {
    text-shadow: 0 0 5px #fff,0 0 10px #fff,0 0 15px #fff,0 0 20px #228dff,0 0 35px #228dff,0 0 40px #228dff;
    background-image: url('./assets/cricket-background.png'); /* Background image related to cricket */
    background-size: cover;
    background-position: center;
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff9800;
    text-align: center;
    position: relative;
}

.hero-content {
    z-index: 2; /* Ensure the content is above the background */
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); Text shadow for better visibility */
}

.hero h1 {
    font-size: 3rem; /* Responsive font size */
    margin-bottom: 20px;
}

.hero p {
    font-size: 1.5rem; /* Responsive font size */
    margin-bottom: 30px;
}

/* Call to Action Button */
.cta-button {
    background-color: #ff9800; /* Orange color for the button */
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;
}

.cta-button:hover {
    background-color: #f57c00; /* Darker shade on hover */
}

/* Info Section */
.info-section {
    padding: 50px 20px;
    text-align: center;
    background: #fff; /* White background for contrast */
}

.info-section h2 {
    font-size: 2.5rem; /* Responsive font size */
    margin-bottom: 20px;
}

.info-section p {
    font-size: 1.2rem; /* Responsive font size */
    max-width: 600px;
    margin: 0 auto; /* Center the text */
}

/* Footer */
.footer {
    text-align: center;
    padding: 20px;
    background: #333; /* Dark background for footer */
    color: white;
}

/* Responsive Design */
@media (max-width: 768px) {
    .hero h1 {
        font-size: 2.5rem; /* Smaller font size on mobile */
    }

    .hero p {
        font-size: 1.2rem; /* Smaller font size on mobile */
    }

    .cta-button {
        font-size: 1rem; /* Smaller font size on mobile */
        padding: 10px 20px; /* Adjust padding on mobile */
    }

    .info-section h2 {
        font-size: 2rem; /* Smaller font size on mobile */
    }

    .info-section p {
        font-size: 1rem; /* Smaller font size on mobile */
    }
}

@media (max-width: 480px) {
    .hero h1 {
        font-size: 2rem; /* Even smaller for very small devices */
    }

    .hero p {
        font-size: 1rem; /* Even smaller for very small devices */
    }
}
</style>
