<template>
  <v-row class="about-us" justify="center" id="services">
    <v-col sm="8" md="8" lg="8" class="a-col-1" >
      <div class="ab-head1">{{ $lan(8) }}</div>
      <div class="wlc f-drop-shadow">
        <div class="text-shadow-red lh-normal">
          <span>{{ $lan(17) }} </span>
          <span>{{ $lan(4) }}</span>
        </div>
      </div>
      <div class="text-shadow-red">
        {{ $lan(18) }}
      </div>
      <div class="d-flex">
        <btn-1 :name="$lan(19) " @click="$wa('whatsapp://send?phone=917900304050&text=Hi%2C%20I%27m%20interested TB!')"></btn-1>
        <btn-2 :name=" $lan(5) " @click="$wa('whatsapp://send?phone=917900304050&text=Hi%2C%20I%27m%20interested TB!')"></btn-2>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import Btn1 from './mobile/Btn1.vue';
import Btn2 from './mobile/Btn2.vue';
export default {
  name: "Home",
  components: { Btn1, Btn2 },
  data: () => ({
    loading: true,
  }),
  mounted() {
    let that = this;
    setTimeout(function () {
      that.setLoader(false);
    }, 2000);
  },
  methods: {
    setLoader(val) {
      try {
        this.loading = val;
      } catch (error) {}
    },
  },
};
</script>
<style scoped>
.about-us {
  background: url("../assets/aboutus/bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.a-col-1 div {
  margin:20px;
}
.a-col-1 {
  height: 465px;
  align-self: center;
  text-align: -webkit-center;
}
</style>
