<template>
  <v-container fluid id="sportid" class="p-25">
    <v-row justify="center">
      <v-col cols="5" class="s-heading h-135"> </v-col>
    </v-row>
    <v-row justify="center">
      <v-col lg="3" md="4" sm="5" class="s-col-1 h-481"> </v-col>
      <v-col lg="3" md="4" sm="5" class="s-col-2 h-481"> </v-col>
      <v-col lg="3" md="4" sm="5" class="s-col-3 h-481"> </v-col>

      <v-col lg="3" md="4" sm="5" class="s-col-4 h-481"> </v-col>
      <v-col lg="3" md="4" sm="5" class="s-col-5 h-481"> </v-col>
      <v-col lg="3" md="4" sm="5" class="s-col-6 h-481"> </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Home",
  data: () => ({
    loading: true,
  }),
  mounted() {
    let that = this;
    setTimeout(function () {
      that.setLoader(false);
    }, 2000);
  },
  methods: {
    setLoader(val) {
      try {
        this.loading = val;
      } catch (error) {}
    },
  },
};
</script>
<style scoped>
.s-col-1 {
  background: url("../assets/service/card1.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.s-col-2 {
  background: url("../assets/service/card2.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.s-col-3 {
  background: url("../assets/service/card3.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.s-col-4 {
  background: url("../assets/service/card4.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.s-col-5 {
  background: url("../assets/service/card5.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.s-col-6 {
  background: url("../assets/service/card6.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.h-481 {
  min-height: 400px;
  background-position: center bottom;
  margin: 10px;
  max-height: 481px;
}
.s-heading {
  background: url("../assets/service/heading.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.h-135 {
  min-height: 135px;
}
</style>
