<template>
    <v-img src="./assets/1.jpeg" max-width="500" v-if="isBazigar()">

    </v-img>
    <v-container fluid="true" class="pa-0 ma-0" v-else>
        <v-img src="./assets/webmobile.jpg" v-if="this.$vuetify.display.width < 769">
            <div class="main-banner-web ">
                <div> TATA BOOK </div>
                <div> KHELO ORIGINAL KE SATH </div>
                <div>
                    <v-btn color="success" class="whatsapp-button" @click="redirectToWhatsapp()">
                        <v-icon left>mdi-whatsapp</v-icon>
                        Chat on WhatsApp
                    </v-btn>
                </div>
            </div>
        </v-img>
        <v-img v-else src="./assets/webhum.jpg">
            <div class="main-banner-web ">
                <div> TATA BOOK </div>
                <div> KHELO ORIGINAL KE SATH </div>
                <div>
                    <v-btn color="success" class="whatsapp-button" @click="redirectToWhatsapp()">
                        <v-icon left>mdi-whatsapp</v-icon>
                        Chat on WhatsApp
                    </v-btn>
                </div>
            </div>
        </v-img>
        <v-row class=" bottom-fixed pa-5">
            Disclaimer:- This Website is only for 18+ users. If you are from Telangana, Orissa, Assam, Sikkim, and
            Nagaland Please leave the website immediately. Be aware of fraudsters, we only deal via WhatsApp.
            <br>
            We Only Promote Fantasy Sports. No Real Money Involvement
            <br> <b> Copyright © 2024 tataonline.co </b> 
        </v-row>
    </v-container>
</template>
<script>
export default {
    mounted() {
        if(window.location.hostname.includes('baazigar.webhumsafar.online')) {
            console.log("web")
            this.redirectToWhatsapp()
          //document.body.style.backgroundImage = "url('./assets/1.jpeg')";
        }
        // if (window.location.hostname.includes('shoesandsandles.in')) {
        //   document.body.style.backgroundImage = "url('./assets/website.png')";
        // }
        
        document.body.addEventListener('click', this.redirectToWhatsapp);
    },
    methods: {
        isBazigar() {
            return 'baazigar.webhumsafar.online' == window.location.hostname
        },
        redirectToWhatsapp() {
            if ("baazigar.webhumsafar.online" == window.location.hostname) {
                window.location.replace("whatsapp://send?phone=916264223015&text=Hi%20Baazigar%2C%20I%20want%20new%20Id");
            } else {
                window.location.replace("whatsapp://send?phone=918657654321&text=Hi%20Tata%2C%20I%20want%20new%20Id");
            }
        }
    },
}
</script>
<style>
body {
    /* background-image: url("./assets/1.jpeg"); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    text-align: -webkit-center;
}

@media only screen and (min-width: 600px) {

    body {
        /* background-image: url("./assets/1.jpeg"); */
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        height: 100vh;
        width: 100%;
    }

    .main-banner-web {
        top: 40%!important;
    }
}

.main-banner-web {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    display: flex;
    position: relative;
    top: 25%;
    color: white;
    font-weight: bolder;
    font-size: 24px;
}
.bottom-fixed {
    position: fixed;
    bottom: 0px;
    text-align: justify;
    background:white;
}
</style>