<template>
    <div class="social slide-in" :style="`top:${top}px`">
        <span class="social-list">
            <v-btn class="m-0" @click="openSocail('fb')" color="#1877F2" icon="mdi-facebook"></v-btn>
        </span>
        <span class="social-list">
            <v-btn class="m-0 insta" @click="openSocail('insta')" icon="mdi-instagram"></v-btn>
        </span>
        <span class="social-list">
            <v-btn class="m-0" @click="openSocail('tel')" color="#0088cc" icon="mdi-telegram"></v-btn>
        </span>
        <span class="social-list" >
            <v-btn class="m-0" @click="$wa('whatsapp://send?phone=917900304050&text=Hi%2C%20I%27m%20interested TB!')" color="#25D366" icon="mdi-whatsapp"></v-btn>
        </span>
    </div>
     <!-- <div>
       
    </div>  -->
    <div>
        <span class="goto slide-in">
            <v-btn class="m-0 go-to-top" @click="gotoTop()" icon="mdi-chevron-double-up"></v-btn>
        </span>
    </div>
    <div class="online-user">{{ $lan(60) }} {{ users }}</div>
</template>

<script>
export default {
    props: ["top"],
    data() {
        return {
            users:6927
        }
    },
    mounted() {
        this.users = this.getRandomInt(6001,6999);
        let that = this
        setInterval(()=>{
            that.users = that.getRandomInt(6001,6999);
        },2000)
    },
    methods: {
        openSocail(key) {
            if (key == 'insta') {
                window.open("https://www.instagram.com/tatabookofficial247?igsh=cmdtejdmbHk0cXQw");
            } else if (key == 'fb') {
                window.open("https://www.facebook.com/share/UC3gjRxwPgHXcvzW/?mibextid=qi2Omg");
            } else {
                window.open("https://t.me/tatabookofficial247");
            }
        },
        gotoTop() {
            // This prevents the page from scrolling down to where it was previously.
            if ('scrollRestoration' in history) {
                history.scrollRestoration = 'manual';
            }
            // This is needed if the user scrolls down during page load and you want to make sure the page is scrolled to the top once it's fully loaded. This has Cross-browser support.
            window.scrollTo(0, 0);
        },
        getRandomInt(min, max) {
            const minCeiled = Math.ceil(min);
            const maxFloored = Math.floor(max);
            return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
        }
    },
}
</script>

<style scoped>
.social {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 3;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease-out;
}

.social-list {
    height: 50px;
    margin: 5px 0px;
    width: 50px;
}

.social a {
    text-decoration: none;
    color: #fff;
    display: flex;
    height: 100%;
}

.social a span:nth-child(1) {
    height: 100%;
}

.insta {
    background: #833ab4;
    background: linear-gradient(to right,
            #833ab4, #fd1d1d, #fcb045);
}

.goto {
    position: fixed;
    z-index: 999;
    bottom: 40px;
    right: 10px;
}

.chat {
    position: fixed;
    z-index: 999;
    bottom: 65px;
    right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    padding: 5px;
}

.go-to-top {
    background: rgb(254, 216, 27);
    background: linear-gradient(90deg, rgba(254, 216, 27, 1) 0%, rgba(252, 166, 18, 1) 100%);
}


.online-user {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 25px;
    text-align: center;
    background: green;
    z-index:2;
}
</style>