<template>
  <v-col cols="12" class="m-get-in-touch" id="git">
    <v-img src="../../assets/mobile/getintouch/bg.png">
      <div class="m-get-in-touch-d1">
        <div class="wlc f-drop-shadow">
          <div class="text-shadow-red lh-normal">
            <span>{{ $lan(47) }} </span>
            <span>{{ $lan(48) }}</span>
          </div>
        </div>
        <div class="text-shadow-red">
          {{ $lan(49) }}
        </div>
      </div>
      <div class="m-get-in-touch-d2">
        <div>
          {{ $lan(50) }}
        </div>
        <span class="get-img" @click="$wa('whatsapp://send?phone=917900304050&text=Hi%2C%20I%27m%20interested TB!')">
          <p>
            <v-img src="../../assets/mobile/getintouch/btn.png" width="36px"></v-img>
          </p>
          <p>+91 7900304050</p>
        </span>
        <span class="get-img" @click="$wa('whatsapp://send?phone=917070770705&text=Hi%2C%20I%27m%20interested TB!')">
          <p>
            <v-img src="../../assets/mobile/getintouch/btn.png" width="36px"></v-img>
          </p>
          <p>+91 7070770705</p>
        </span>
      </div>
      <div class="m-get-in-touch-d3">
        <div>
          {{ $lan(51) }}
        </div>
        <div>{{ $lan(52) }}</div>
        <div @click="$wa('whatsapp://send?phone=917900304050&text=Hi%2C%20I%27m%20interested TB!')">
          {{ $lan(53) }}
        </div>
      </div>
      <v-img src="../../assets/mobile/getintouch/doll.png" class="getlogo">
        <v-img src="../../assets/logo.gif" width="60%"> </v-img>
        <div class="_18plus">{{ $lan(54) }}</div>
        <!-- <v-img src="../../assets/gif/18.gif" width="20%"></v-img> -->
      </v-img>
      <div class="copyright">Copyright© 2024 TataBook</div>
    </v-img>
  </v-col>
</template>

<script>
export default {
  name: "getintouch"
}
</script>

<style scoped>
._18plus {
  padding: 10px;
  font-weight: bold;
  font-size: 12px;
}

.getlogo {
  text-align: -webkit-center;
}

.m-get-in-touch {
  padding: 0px;
  padding-bottom: 37px !important;
  min-height: 400px;

}

.m-get-in-touch-d3 div:nth-child(1) {
  color: #fff;
  font-size: 28px;
  font-weight: bold;
}

.m-get-in-touch-d3 div:nth-child(2) {
  color: #FED51E;
  font-size: 18px;
  font-weight: bold;
}

.m-get-in-touch-d3 div:nth-child(3) {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  background: rgb(254, 216, 27);
  background: linear-gradient(90deg, rgba(254, 216, 27, 1) 0%, rgba(252, 166, 18, 1) 100%);
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}

.m-get-in-touch-d1 {
  margin: 10px 44px;
}

.m-get-in-touch-d2 div:nth-child(1) {
  letter-spacing: 2px;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}

.m-get-in-touch-d2 .get-img {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 15px;
  background: #2BC340;
  border-radius: 10px;
  padding: 15px;
}

.m-get-in-touch-d2 {
  margin: 10px 44px;
  background: #3C3C3E;
  height: 301px;
  border-radius: 25px;
  padding: 30px;

}

.copyright {
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.m-get-in-touch-d3 {
  margin: 10px 44px;
  background: #3C3C3E;
  height: 301px;
  border-radius: 25px;
  padding: 33px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


@media only screen and (max-width: 330px) {
  .wlc {
    font-size: 35px;
  }

  .m-get-in-touch-d2 .get-img {
    font-size: 13px !important;
    padding: 12px !important;
    ;
  }

  .m-get-in-touch-d2 {
    padding: 20px;
    height: 233px;
  }
}

@media only screen and (max-width: 375px) {
  .m-get-in-touch-d3 div:nth-child(1) {
    font-size: 22px;
  }

  .m-get-in-touch-d3 div:nth-child(2) {
    font-size: 15px;
  }

  .m-get-in-touch-d3 div:nth-child(3) {
    font-size: 15px;
  }

  .m-get-in-touch-d2 .get-img {
    font-size: 15px;
    padding: 12px;
  }

  .m-get-in-touch-d2 {
    height: 250px;
  }
}
</style>